<script setup lang="ts">

</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.37 20.91">
    <polygon points="7.66 10.4 1.54 4.29 1.54 16.51 7.66 10.4" style="fill:none; stroke-width:0px;" />
    <polygon points="19.54 10.4 25.71 16.57 25.71 4.23 19.54 10.4" style="fill:none; stroke-width:0px;" />
    <path
      d="m11.31,11.83c1.26,1.26,3.37,1.26,4.63,0L25.67,2.1c-.1-.33-.38-.56-.75-.56H2.34c-.39,0-.68.25-.76.61l9.73,9.68Z"
      style="fill:none; stroke-width:0px;" />
    <path
      d="m18.45,11.48l-1.49,1.49c-.91.91-2.11,1.43-3.37,1.43s-2.46-.51-3.37-1.43l-1.49-1.49-7.16,7.16c.08.35.38.61.76.61h22.57c.37,0,.66-.23.75-.56l-7.21-7.21Z"
      style="fill:none; stroke-width:0px;" />
    <path
      d="m24.97,0H2.4C1.09,0,0,1.09,0,2.4v16.11c0,1.31,1.09,2.4,2.4,2.4h22.57c1.31,0,2.4-1.09,2.4-2.4V2.4c0-1.31-1.09-2.4-2.4-2.4Zm-5.43,10.4l6.17-6.17v12.34l-6.17-6.17ZM2.34,1.54h22.57c.37,0,.66.23.75.56l-9.72,9.72c-1.26,1.26-3.37,1.26-4.63,0L1.58,2.15c.08-.35.38-.61.76-.61Zm5.31,8.86l-6.11,6.11V4.29l6.11,6.11Zm17.26,8.86H2.34c-.39,0-.68-.25-.76-.61l7.16-7.16,1.49,1.49c.91.91,2.11,1.43,3.37,1.43s2.46-.51,3.37-1.43l1.49-1.49,7.21,7.21c-.1.33-.38.56-.75.56Z"
      style="fill:currentColor; stroke-width:0px;" />
  </svg>
</template>

<style scoped>

</style>
